import MainContainer from "./MainContainer/MainContainer";

function App() {
  return (
    <>
      <MainContainer/>
    </>
  );
}

export default App;
